<template lang="pug">
.form-group
  .flex-between
    label.control-label Print sections
    HelpPopover(title="Print sections" :content="this.printHelp")
  div
    DropdownCheckboxes(
      label="Print Sections"
      v-model="sectionIds"
      :options="$store.getters['sections/enabledAsObject']"
      class="block"
    )
</template>

<script>
  import DropdownCheckboxes from '../../dropdown_checkboxes.vue'
  import HelpPopover from '../../shared/help_popover.vue';

  export default {
    setup() {
      const printHelp = `In which section of the print newspaper is this story expected to appear. This can be changed any time.

These sections can be edited or changed by your system administrator.`

      return { printHelp }
    },

    components: {
      DropdownCheckboxes,
      HelpPopover
    },

    props: {
      // Array of values from options
      // NOTE: be careful that the values match the options values. Number strings are a common issue.
      value: {
        type: Array,
        default() { return [] }
      }
    },

    data() {
      return {
        sectionIds: []
      }
    },

    watch: {
      value() {
        this.sectionIds = this.value
      },

      sectionIds() {
        // For component v-model support
        this.$emit('input', this.sectionIds);
      }
    },

    async mounted() {
      await this.$store.dispatch('sections/fetch')
    }
  }
</script>
