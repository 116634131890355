<template lang="pug">
.panel.panel-default.pull-quotes
  .panel-heading
    .row
      .col-md-6
        h4(style="margin-top: 7px") Pull quotes
      .col-md-6.text-right
        HelpPopover(title="Pull quotes" :content="this.pullquoteHelp")
        ShowHideButton.btn-sm(v-model="showPullQuotes")

  .panel-body(v-show="showPullQuotes")
    .form-group
      label.control-label Headline
      input.form-control(type="text" v-model="headline")

    .form-group
      label.control-label Quote
      textarea.form-control(v-model="quote")

    .form-group
      label.control-label Attribution
      input.form-control(type="text" v-model="attribution")
</template>

<script>
  import { sync } from 'vuex-pathify'
  import HelpPopover from '../../shared/help_popover.vue'
  import ShowHideButton from '../../show_hide_button.vue'

  export default {
    setup() {
      const pullquoteHelp = `This is an opportunity to call attention to an interesting quote or passage in your story. This can help give your story “voice” and attract more readers, just as a good deck head does.

Ideally, quotes should be short and interesting.

You can add an attribution line or a headline to help give the quote context, if needed.

You can add additional pull quotes in the web CMS, if needed.`

      return {
        pullquoteHelp
      }
    },

    components: {
      HelpPopover,
      ShowHideButton
    },

    props: {
      storyId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        showPullQuotes: false
      }
    },

    computed: sync('pullQuote/*'),

    watch: {
      headline(val) { if(val) { this.showPullQuotes = true; } },
      quote(val) { if(val) { this.showPullQuotes = true; } },
      attribution(val) { if(val) { this.showPullQuotes = true; } },
    },

    mounted() {
      this.$store.commit('pullQuote/RESET')
      this.$store.dispatch('pullQuote/fetchForStory', { storyId: this.storyId });
    }
  };
</script>
