<template lang="pug">
.form-group
  .flex-between
    label.control-label Web categories
    HelpPopover(title="Web categories" :content="this.webCategoriesHelp" placement="left")
  div
    DropdownCheckboxes.block(label="Web categories" v-model="webCategoryIds" :options="webCategoriesStore.allAsObject")
</template>

<script>
  import { mapStores } from 'pinia'
  import { useWebCategoriesStore } from '../../../stores/web_categories'
  import DropdownCheckboxes from '../../dropdown_checkboxes.vue'
  import HelpPopover from '../../shared/help_popover.vue';

  export default {
    setup() {
      const webCategoriesHelp = `How do you want this story to be categorized on the website? Often websites have more specific categories than in print and online you can put a story into more than one category.

These categories are pulled from your web CMS and can be edited, added or deleted in the website's taxonomy section.`



      return { webCategoriesHelp }
    },

    components: {
      DropdownCheckboxes,
      HelpPopover
    },

    props: {
      // Array of values from options
      // NOTE: be careful that the values match the options values. Number strings are a common issue.
      value: {
        type: Array,
        default() { return [] }
      }
    },

    data() {
      return {
        webCategoryIds: []
      }
    },

    watch: {
      value() {
        this.webCategoryIds = this.value
      },

      webCategoryIds() {
        // For component v-model support
        this.$emit('input', this.webCategoryIds);
      }
    },

    computed: {
      ...mapStores(useWebCategoriesStore)
    },

    async mounted() {
      await this.webCategoriesStore.fetchOnce()
    }
  }
</script>
