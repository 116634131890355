<template lang="pug">
a(class="btn btn-secondary" tabindex="0" role="button" data-toggle="popover" :data-placement="placement" data-trigger="hover" :title="title" :data-content="content")
  i.fa.fa-question
</template>

<script>
  export default {
    props: {
      content: {
        type: String,
        required: true
      },
      placement: {
        type: String,
        default: 'left'
      },
      title: String
    },

    async mounted() {
      jQuery('[data-toggle="popover"]').popover({
        container: 'body'
      });
    },

    async updated() {
      jQuery('[data-toggle="popover"]').popover({
        container: 'body'
      });
    }
  }
</script>

<style lang="scss">
.popover {
  max-width: 35% !important;
  .popover-title {
    font-size: 1.5em;
  }
  .popover-content {
    white-space: pre-wrap !important;
  }
}
</style>
