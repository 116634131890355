<template lang="pug">
.form-group
  .flex-between
    label.control-label Byline
    HelpPopover(title="Byline" :content="this.bylineHelp")
  vue-simple-suggest.byline(
    v-model="inputValue"
    :list="bylinePresetList"
    :min-length="0"
    :filter-by-query="true"
    :readonly="readOnly"
    :destyled="true"
    :styles="customStyles"
  )

  .row.buttons-row
    .col-sm-6
      slot
    .col-sm-6(v-if="perFieldFileMode")
      .btn-group.pull-right
        button.btn.btn-default.btn-sm(type="button" :class="{ active: !useFileContent }" v-on:click="editHere()")
          i.fa.fa-pencil-square-o
          |
          | Edit Here
        label.btn.btn-default.btn-sm.pull-left(type="button" :class="{ active: useFileContent }" v-on:click="useFileContent = true")
          i.fa.fa-file-text-o
          |
          | Edit File
          input(type="file" v-on:change="fileChosen($event)" style="display: none")
</template>

<script>
  import FileContentLinkedInput from '../../mixins/file_content_linked_input';
  import HelpPopover from '../../shared/help_popover.vue'
  import VueSimpleSuggest from 'vue-simple-suggest/lib';
  import { mapStores } from 'pinia'
  import { useBylinePresetsStore } from '../../../stores/byline_presets'
  import { useCurrentUserStore } from '../../../stores/current_user'

  export default {
    mixins: [FileContentLinkedInput],

    setup() {
      const bylineHelp = `The byline field is used to identify the author of this story. They can include multiple names.

Any saved bylines created in your web CMS will be available in a drop down list here.

Bylines can be links that spawn an email or take readers to the writer's social media feed or online profile.

Your website administrator can edit or add saved bylines.`

      return { bylineHelp }
    },

    components: {
      HelpPopover,
      VueSimpleSuggest
    },

    props: {
      contentFor: {
        type: String,
        default: () => 'byline'
      }
    },

    data() {
      return {
        customStyles: {
          vueSimpleSuggest: "position-relative",
          inputWrapper: "",
          defaultInput : "form-control",
          suggestions: "position-absolute list-group z-1000",
          suggestItem: "list-group-item"
        }
      }
    },

    computed: {
      ...mapStores(
        useBylinePresetsStore,
        useCurrentUserStore
      )
    },

    async mounted() {
      await this.bylinePresetsStore.fetch()
    },

    methods: {
      bylinePresetList() {
        // If it's read-only, don't suggest changes when they focus on the input
        if(this.readOnly) return []

        return [
          this.currentUserStore.currentUser.byline,
          ...this.bylinePresetsStore.bylinePresets,
        ].filter(Boolean); // Remove empty strings
      },

      contentFileSuccessHandler(data) {
        this.fileContent = data.content;
        this.inputValue = data.content;
        this.useFileContent = true;
      }
    }
  }
</script>

<style lang="scss">
  .byline {
    .list-group-item {
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }
  }
</style>
