<template lang="pug">
.form-group(v-if="currentUserStore.currentUser.can_set_squeue")
  .flex-between(v-if="showLabel")
    label.control-label
      span.required(v-if="required") *
      |
      | Status
    HelpPopover(title="Status" :content="this.statusHelp")
  select.form-control(:value="value" @input="updateSqueueId", :disabled="disabled")
    option(v-if="!required" :value="null")
    option(v-for="squeue in squeues" :value="squeue.id") {{ squeue.title }}
</template>

<script>
  import { mapStores } from 'pinia'
  import { useCurrentUserStore } from '../../stores/current_user'
  import HelpPopover from './help_popover.vue'

  export default {
    setup() {
      const statusHelp = `This field tracks the status of a story along its journey from conception to publication. By default, the system assigns a story to “default” or Original status but your site administrator can create a different default status name.

Some clients use newsroomQ to assign stories to reporters, so a reporter can log in and see their “assignments” for the day. So you might want to set a default status to “Assigned.” Some clients only enter stories after editing them outside the system. In that case, you might want the default status to be “Edited” or “Ready for layout.”

Changing the status also color codes stories so everyone can quickly see what stories need more attention. So remember to change your story’s status when it is ready for the next step in production.

Your administrator can create or change statuses under SETTINGS or contact support.`

      return { statusHelp }
    },

    components: {
      HelpPopover
    },

    props: {
      // NOTE: be careful that the value matches the option value. Number strings are a common issue.
      value: {
        type: Number,
        default() { return null }
      },
      required: {
        type: Boolean,
        default() { return false }
      },
      showLabel: {
        type: Boolean,
        default() { return true }
      },
      disabled: {
        type: Boolean,
        default() { return false }
      }
    },

    computed: {
      ...mapStores(useCurrentUserStore),

      squeues() {
        const squeues = this.$store.getters['squeues/enabled']

        return squeues
      }
    },

    async created() {
      await this.$store.dispatch('squeues/fetchOnce')
    },

    methods: {
      updateSqueueId({ target: { value }}) {
        const numValue = Number(value)
        const id = numValue === 0 ? null : numValue

        this.$emit('input', id)
      }
    }
  }
</script>
