<template lang="pug">
.form-group
  div(v-if="show")
    .flex-between
      label.control-label Subsites
      HelpPopover(title="Subsites" :content="this.subsiteHelp")
    div
      DropdownCheckboxes(
        label="Subsites"
        :value="value"
        @input="emitInput"
        :options="subsiteOptions"
        class="block"
      )
</template>

<script>
  import { mapGetters } from 'vuex'
  import { isEmpty } from 'lodash'

  import DropdownCheckboxes from '../../dropdown_checkboxes.vue'
  import HelpPopover from '../../shared/help_popover.vue'

  export default {
    setup() {
      const subsiteHelp = `If your newsroomQ platform is set up to feed multiple publications or websites, you can choose which website you will be sending this story to here.

You can choose more than one website to send your story to.`

      return { subsiteHelp }
    },

    components: {
      DropdownCheckboxes,
      HelpPopover
    },

    props: {
      publicationId: {
        type: Number,
        required: true,
      },

      // Array of values from options
      // NOTE: be careful that the values match the options values. Number strings are a common issue.
      value: {
        type: Array,
        default() { return [] }
      }
    },

    computed: {
      ...mapGetters('subsites', { subsitesForPub: 'forPublicationAsObject' }),

      subsiteOptions() {
        return this.subsitesForPub(this.publicationId)
      },

      show() {
        return !isEmpty(this.subsiteOptions)
      }
    },

    async mounted() {
      await this.$store.dispatch('subsites/fetchOnce')
    },

    methods: {
      emitInput(subsiteTownIds) {
        // For component v-model support
        this.$emit('input', subsiteTownIds);
      }
    }
  }
</script>
