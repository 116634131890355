<template lang="pug">
article.edit
  CommunityQPublishModal
  StoryEmailModal

  form(v-on:submit.prevent="saveButtonAction")

    nav.story-header.container.sticky-top
      .row
        .col-md-6
          h1
            | Edit Story

        .col-md-6.text-right
          .btn-group
            button.btn.btn-success(type="button" :disabled="lockedToUser" v-on:click="saveButtonAction")
              i.fa(:class="saveButtonClass")
              |
              | {{ saveButtonText }}

            a.btn.btn-prominent.btn-info(@click="goBack()")
              i.fa.fa-arrow-left
              |
              | Back

            template(v-if="exportable")
              MoreButton(
                :story-id="storyId"
                :story-name="name"
                :without="['mark-published', 'duplicate', 'delete', 'divider']"
                :locked="lockedToUser"
              )

            template(v-if="!spiked")
              button.btn.btn-danger(type="button" :disabled="lockedToUser" @click.prevent="spikeStory")
                i.fa.fa-trash

      .row
        .col-md-6
          p.server-path
            i.fa.fa-hdd-o
            |
            |
            a(v-if="openFolderUrl" :href="openFolderUrl")
              | {{ contentPath }}
            span(v-else)
              | {{ contentPath }}
        .col-md-6.text-right
          p.updated-at(title='Last modified')
            i.fa.fa-clock-o
            |
            | {{ lastUpdated }}
    LockedOverlay(:story-id="storyId")
      .row
        .col-md-7
          LoaderOverlay(:loading="loading")
            .form-group(:class="{ 'has-error': hasError['slug'] }")
              .flex-between
                label.control-label
                  span.required *
                  |
                  | Story name or slug
                HelpPopover(title="Story name or slug" :content="this.slugHelp")
              Input(v-model="slug")

            .form-group
              .flex-between
                label.control-label Overline
                HelpPopover(title="Overline" :content="this.overlineHelp")
              SingleFileLinkedTextField(v-model="overline")

            .form-group
              .flex-between
                label.control-label Headline
                HelpPopover(title="Headline" :content="this.headlineHelp")
              FileContentLinkedField(content-for="headline" v-model="headline" class="headline-container" input-class="input-lg")

            .form-group
              .flex-between
                label.control-label Deck head
                HelpPopover(title="Deck head" :content="this.deckheadHelp")
              SingleFileLinkedTextField(v-model="dropHead")

            BylineField(v-model="byline")

          LoaderOverlay(:loading="loading")
            .form-group
              br
              FileContentLinkedWysiwyg(content-for="content" v-model="content" label="Story body" :loading="loading" :help="this.bodyHelp")

          .form-group
            RichtextEditor(v-model="tag" style="simple" height="100" :loading="loading" classes='form-control-sm' label="Tag line" :showCounts="false" :help="this.taglineHelp")

          LoaderOverlay(:loading="loading")
            .panel.panel-default
              .panel-heading
                .row
                  .col-md-6
                    h4(style="margin-top: 7px") Breakout box
                  .col-md-6.text-right
                    HelpPopover(title="Breakout box" :content="this.breakoutboxHelp")
                    ShowHideButton.btn-sm(v-model="showInfobox")
              .panel-body(v-show="showInfobox")
                .form-group
                  label.control-label Headline
                  Input(v-model="infoboxHeadline")
                .form-group
                  FileContentLinkedWysiwyg(content-for="infobox_text" v-model="infoboxText" label="Content" :height="150" :loading="loading")

            PullQuote(:story-id="storyId")

            .panel.panel-default
              .panel-heading
                .flex-between
                  h4
                    | Optimizing for the web
                  span
                    HelpPopover(title="Optimizing for the web" :content="this.webHelp")
                    ShowHideButton.btn-sm(v-model="showCommunityQ")
              .panel-body(v-show="showCommunityQ")
                .form-group
                  label.checkbox-inline
                    input(type="checkbox" v-model="featured")
                    |
                    | Featured
                  label.checkbox-inline
                    input(type="checkbox" v-model="ispublic")
                    |
                    | Free
                  label.checkbox-inline
                    input(type="checkbox" v-model="commentsDisabled")
                    |
                    | Comments disabled
                hr

                .form-group
                  .flex-between
                    label.control-label Alt web headline
                    HelpPopover(title="Alt web headline" :content="this.webHeadlineHelp")
                  Input(v-model="webHead")

                .form-group
                  .flex-between
                    label.control-label Story URL
                    HelpPopover(title="Story URL" :content="this.webSlugHelp")
                  Input(v-model="webSlug")

                .form-group
                  .flex-between
                    label.control-label Web lead or summary
                    HelpPopover(title="Web lead or summary" :content="this.webLeadHelp")
                  TextareaInput(name="story[web_summary]" v-model="webSummary" rows="4")

                .form-group
                  .flex-between
                    label.control-label Web story body
                    HelpPopover(title="Web story body" :content="this.webBodyHelp")
                  RichtextEditor(name="story[web_body]" v-model="webBody" style="simple" height="150" :loading="loading" classes="form-control-lg")

                .form-group
                  .flex-between
                    label.control-label Keywords
                    HelpPopover(title="Keywords" :content="this.webKeywordsHelp")
                  Input(v-model="keywords")
                StoryPublications(:story-id="storyId")


        .col-md-5
          LoaderOverlay(:loading="loading")
            MediaIndex(:story-id="storyId")

            hr

          LoaderOverlay(:loading="loading")
            .flex-between
              label.control-label Export mode
              HelpPopover(title="Export mode" :content="this.exportModeHelp" placement="left")
            .btn-group.btn-group-justified
              .btn-group
                button.btn.btn-default(ref="fileModePerField" type="button" :class="{active: perFieldFileMode}" v-on:click="fileMode = 'per_field'" data-toggle="tooltip" data-placement="top" title="A separate file for each content field")
                  | File Per Field
              .btn-group
                button.btn.btn-default(ref="fileModeSingle" type="button" :class="{active: singleFileMode}" v-on:click="fileMode = 'single'" data-toggle="tooltip" data-placement="top" title="One file contains content for multiple fields")
                  | Single File

            div(v-if="singleFileMode" style="margin-top: 5px;")
              div(v-if="singleFilePresent")
                .input-group
                  span.input-group-addon
                    i.fa.fa-file-text
                  Input(:value="singleFile.medium.filename" :disabled="true")
                  span.input-group-btn
                    label.btn.btn-info(type="button")
                      i.fa.fa-cloud-upload
                      input(type="file" v-on:change="singleFileChosen($event)" style="display: none")

              div(v-else)
                .btn-group.new-single-file
                  button.btn.btn-success(type="button" v-if="masterTemplate" @click="generateNewSingleFile = true" :class="{ active: generateNewSingleFile}")
                    i.fa.fa-file-text
                    |
                    | Generate File
                  label.btn.btn-info(type="button"  @click="generateNewSingleFile = false" :class="{ active: !generateNewSingleFile}")
                    i.fa.fa-cloud-upload
                    |
                    | Attach File
                    input(type="file" v-on:change="singleFileChosen($event)" style="display: none")

            hr

            .row
              SqueueField.col-md-6(v-model="squeueId" :required="true")

              UserField.col-md-6(v-model="userId" :default="currentUser.id")

              SectionsField.col-md-6(v-model="sectionIds")

              WebCategoriesField.col-md-6(v-model="webCategoryIds")

          LoaderOverlay(:loading="loading")
            hr

            .form-group
              label.control-label Issues
              |
              HelpPopover(title="Issues" :content="this.issuesHelp" placement="left")
              Placements(:story-id="storyId")

            .row
              .col-md-6
                .form-group
                  .flex-between
                    label.control-label Pub date
                    HelpPopover(title="Pub date" :content="this.pubDateHelp")
                  .form-inline
                    DateField(v-model="pubDate")

            hr

            .form-group
              .flex-between
                label.control-label Notes
                HelpPopover(title="Notes" :content="this.notesHelp" placement="left")
              TextareaInput(v-model="notes")

            template(v-if="currentUser.show_advert_fields")
              hr

              .form-group
                label.control-label Twitter Feed
                Input(v-model="twitterFeed")
              .form-group
                label.control-label Tweet
                Input(v-model="tweet")
              .form-group
                label.control-label Google Search Ad
                Input(v-model="googleSearchAd")
              .form-group
                label.control-label Outbrain Ad
                Input(v-model="outbrainAd")

              hr

            .form-group
              label.checkbox-inline
                input(type="checkbox" v-model="freelance")
                |
                | Freelance
              HelpPopover(title="Freelance" :content="this.freelanceHelp")

              hr

            .form-group
              AutoExportSelectionsField
</template>

<script>
  // Utils
  import axios from 'axios';
  import { isEmpty } from 'lodash'
  import Vuex from 'vuex'
  import { sync } from 'vuex-pathify';
  import { mapStores, mapState } from 'pinia'
  import { useCurrentUserStore } from '../../../stores/current_user'
  import { storyRoute } from '../../../utils/route_helpers.js'
  import { stateFingerprint } from '../../../utils/state_helpers.js'
  import { formatDateTime } from '../../../utils/time_helpers.js'

  // Vue Components
  import AutoExportSelectionsField from './auto_export_selections_field.vue'
  import BylineField from './byline_field.vue'
  import CommunityQPublishModal from '../../community_q/publish_modal.vue'
  import CustomFolders from './custom_folders.vue'
  import DateField from '../../shared/date_field.vue'
  import FileContentLinkedField from './file_content_linked_field.vue'
  import FileContentLinkedWysiwyg from './file_content_linked_wysiwyg.vue'
  import HelpPopover from '../../shared/help_popover.vue'
  import Input from '../../shared/input.vue'
  import LoaderOverlay from '../../shared/loader_overlay.vue'
  import LockedOverlay from './locked_overlay.vue'
  import Placements from './placements/index.vue'
  import PullQuote from './pull_quote.vue'
  import RichtextEditor from '../../../components/shared/richtext_editor'
  import MediaIndex from './media/index.vue'
  import SectionsField from './sections_field.vue'
  import ShowHideButton from '../../show_hide_button.vue'
  import SingleFileLinkedTextField from './single_file_linked_text_field.vue'
  import SqueueField from '../../shared/squeue_field.vue'
  import StoryEmailModal from '../../stories/email_modal.vue'
  import StoryPublications from './story_publications.vue'
  import TextareaInput from '../../shared/textarea_input.vue'
  import UserField from './user_field.vue'
  import WebCategoriesField from './web_categories_field.vue'
  import MoreButton from '../actions/more_button.vue'

  const BASE64_URL_MATCHER = /^data:[^;]*;base64,.+/
  const UNLOAD_MSG = 'Sure you want to leave? Your changes will be lost.'

  export default {
    setup() {
      const slugHelp = `Every story has to have a story name or slug. This is for internal use only and is never seen by readers.

Use this to create a unique identifier for the story. Many newsrooms include dates or author names or other clues about what this story is before headlines or anything else is written.

These “slugs” or descriptors are often used on story budgets. Example: "Jones 8-22-25 parade” or you can just use your proposed headline: “Parade is a music extravaganza”

This field can be edited later if you need to change the story name.`

      const overlineHelp = `An overline or kicker appears above the main headline when a story is displayed online. It can also be used in print.

Overlines are typically set in smaller, bolder type. They are often used to display a general topic, category or location, making the headline easier to write, especially in print. So it might say “NCAA FINALS” or “EAST PROVIDENCE,” getting those words out of the headline itself and making room for other information.

In print, overlines also often help scanning readers find the stories they are most interested in, especially if they are in color. A reader can just glance at a page and “GIRLS BASKETBALL” can stand out faster to help me know if that story is something I want to read.`

      const headlineHelp = `This is the main headline for your story. Typically, this is the headline that will appear in print.

By default, this becomes part of the URL of your story online, which is important for maximizing your SEO. But the web URL itself can be edited below, so you don't have to rewrite the actual headline that will appear on your story to improve your SEO. You can also write an alternative headline for the web below, which might be longer than the space available in print.

Ideally, headlines should use casual language and avoid bureaucratic words or acronyms. They should also be about readers or people, not agencies or reports or meetings. Try to focus on how your story impacts readers. “Our property taxes are increasing next year” might be better than the traditional “NBRL city council votes to raise property taxes next year."`

      const deckheadHelp = `Deck heads or subheads are secondary headlines that usually appear below the main headline in a smaller size.

These add to your SEO online and give the skimming reader more information when flipping through a print newspaper. We recommend you use them more often online and in print.

The best deck headlines give scanning readers more detail about the story but avoid taking both the headline and deck head information from the top. Good deck headlines are often based on elements that are deeper into a story.`

      const bodyHelp = `This is the field where you enter the content of your story.

You can write in this box or cut and paste from Word, Google, InCopy or other document files. Coding in this box will attempt to preserve formatting and links.

Be wary of cutting and pasting from other websites. This can introduce coding or formatting that may disrupt the display of your story online.

Typically, your browser can provide spell checking.

Using the tools above this box, you can revert to a previous version if you haven't saved. You can bold face or italicize words, embed links, or erase formatting. You can add subheads using “Heading 2" under the style button. Hover over the boxes above to see what each does. `

      const taglineHelp = `Publications often use a paragraph at the end of the story to tell readers about the author's background or qualifications or how they can contact the writer.

If you just set these tag lines in italic body type, they can be easily formatted within the story body field, but if these notes need to be formatted very differently from body type, this box can use different typesetting styles to help this text stand out.

This formatting may not be the same online as it will be in print.`

      const breakoutboxHelp = `A breakout box is a short block of text to highlight and draw readers' attention to a detail or factoid in print and online.

All breakout or info boxes will appear online but they can be skipped for print if space is limited.

Online these breakouts will be shouldered into the story body.

A good use of a breakout box might be to highlight what's coming up next, like the next game for the local football team. It might also be used for “If you go…” details like where to park or buy tickets or eat if you are attending an event.

You can add additional breakout boxes in the web CMS, if needed.`

      const webHelp = `You can use the fields in this box to override print story elements to be different online.

For instance, if you have to cut a story for length in print, you can send the full original text to your website. You can write a different headline on the web that the one used in print.

You can also set up features for your story online that aren't available in print.`

      const webSlugHelp = `This field is the key to improving your Search Engine Optimization or SEO for your story. This is the text that search engines pay the most attention to. Initially, this field will be filled with the headline of the story. But you can edit this field to add some key words that will greatly improve search results.

If the primary focus of the story is a person or location or event, be sure those words are in this field.

The goal here is to have words someone would search for to find this story. It doesn't have to be a sentence or a headline. It can just be a string of key words. Note that you can delete words like “the,” “and” or “by” because none of these would be words you would use in a search.

Note that this text will become part of the URL of your story, so you have to strictly follow this format: all lower case, hyphens between words, no punctuation, no spaces, no symbols. In most cases, the system will convert what you write to the proper format.

But you can't make this line too long. You should limit this field to 10 to 16 words.

Once filled in, this field will stay the same, so it might be best to edit or check this field after you've finished editing the headline itself.

This field can also be edited in the web CMS but never edit this field after a story is live because that will create errors in search engines.`

    const webHeadlineHelp = `This field can be used to replace the headline you are using in print to a different one online.

This might be helpful if you had to cut your print headline to fit and like the longer one better. You might also use this field if your print headline was incomplete — like if you just used a label headline like “Kid stuff” or “Last try” and that headline would not be very useful online.

Publishers used to writ alternate web headlines more often to improve SEO but it's much more effective to just edit the Story URL field because that doesn't have to be a complete headline. You can get more relevant words into the URL than you could an alt headline.

While space is less of a factor online, don't overdo it. A long headline online can look bad visually or be hard to read for a scanning reader. Consider adding a deck or overline if you have a lot to say!`

    const webLeadHelp = `This is the paragraph that will display in list views and on your home page under that headline.

The lead field is automatically populated with roughly the first 250 characters of your story.

You should look at this paragraph to see if it breaks at an appropriate place. You can delete or add words to finish a thought or a sentence to help get the thrust of your story into this paragraph.

While you can go beyond 250 characters — even doubling that limit or more — making this field much longer can disrupt the display of text on your website. when four stories line up, the one with a long lead with create awkward white space.

You can also write your own summary or tease for your story.

Once this field is changed, it will not update unless you do so manually. You can also delete what you've written and it will restore the 250 character original.`

      const webBodyHelp = `This field is optional. If you do nothing, your story body from above will go online.

This field give you the ability to use a different version of your story online than in print.

The most common reason to use this feature is if you had to cut the story significantly for print but want to use the full version online.

If you are about to cut a story for print, first copy and paste the original here. Then cut the print version. The print version will be exported for InDesign and the web version of the story will go to the website.`

      const webKeywordsHelp = `Keywords can help users find your story in search results on your website. Search engines also use these keywords when indexing your content, although their weight in search algorithms has been diminished substantially.

Be aware that keyword stuffing can negatively affect your search engine ranking.

The best use of keywords is a recurring event or topic, like “The Jones murder trial."`

      const attachmentsHelp = `Attachments are items that are linked with your story in print or online. Attachments can include photos, videos, audio files,  PDFs, and Word or Excel documents.

You can drag and drop multiple attachments at a time over this box to load them into the system. Or the upload button can be used to select attachments on your computer individually. The arrow next to the upload button opens the attachment library or media manager.

Any captions or credit lines in the image metadata will be brought into the system.

Click on “Edit Attachments” to add or edit captions, delete files or indicate whether a photo is for print or web or both.

To change the order of images, click on the image and drag it up or down in the list view.

The system will not make other files available for print but will send them to your website.`

      const exportModeHelp = `This only applies to sites using Adobe InCopy files.

Enable this option if you're using an InCopy document to manage the details for this story. Note that in order to use this function, a master document must already be configured within the export format settings.`

      const issuesHelp = `This area can help you organize your print publication workflow and track content based on when it will be published.

Stories can be assigned to issues of your publication as well as specific sections within your publication.

Editors or administrators can create issues for upcoming publications under the Issues tab in the main navigation bar. Once created, stories can be assigned to any issue.

This can help designers find the stories they need for the pages or sections they are working on.

Using issues is optional.`

      const pubDateHelp = `If you don't use Issues, you might just assign stories to a pub date that you expect to use for your story.

Using the pub date is optional.`

      const notesHelp = `Notes are for internal use only, they do not get published with the story. Your team may decide to use this field for specific kinds of communication.

You can note that you've checked an oddly spelled name or that you are waiting for photos or any other details about the story.`

      const freelanceHelp = `Check this box if the story was written by a freelancer and you want to be able to go back a find all the freelance stories for invoicing and payment purposes.`

      return {
        slugHelp, overlineHelp, headlineHelp, deckheadHelp, bodyHelp, taglineHelp, breakoutboxHelp,
        webHelp, webSlugHelp, webHeadlineHelp, webLeadHelp, webBodyHelp, webKeywordsHelp, notesHelp,
        attachmentsHelp, exportModeHelp,issuesHelp, pubDateHelp, freelanceHelp
      }
    },

    components: {
      AutoExportSelectionsField,
      BylineField,
      CommunityQPublishModal,
      CustomFolders,
      DateField,
      FileContentLinkedField,
      FileContentLinkedWysiwyg,
      HelpPopover,
      Input,
      LoaderOverlay,
      LockedOverlay,
      MoreButton,
      Placements,
      PullQuote,
      RichtextEditor,
      MediaIndex,
      SectionsField,
      ShowHideButton,
      SingleFileLinkedTextField,
      SqueueField,
      StoryEmailModal,
      StoryPublications,
      TextareaInput,
      UserField,
      WebCategoriesField
    },

    props: {
      storyId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        showMoreHeadlines: false,
        showInfobox: false,
        showCommunityQ: true,
        hasError: {},
        warnOnLeave: true,
        saveButtonHover: false,
        saving: false,
        recentlySaved: false,
        generateNewSingleFile: true,
        loading: true
      };
    },

    computed: {
      ...Vuex.mapState(
        'story', ['id', 'locker']
      ),
      ...Vuex.mapGetters(
        'story',
        [ 'lockedToUser' ]
      ),
      ...Vuex.mapGetters(
        'story', [
          'spiked',
          'contentPath',
          'openFolderUrl',
          'singleFileMode',
          'perFieldFileMode',
          'cleanFingerprint',
          'isDirty',
          'updatedAt',
        ]
      ),
      ...Vuex.mapGetters(
        'settings', ['masterTemplate']
      ),
      ...mapStores(useCurrentUserStore),
      ...mapState(useCurrentUserStore, ['currentUser']),

      name() {
        return this.headline ? this.headline : this.slug;
      },

      exportable() {
        return this.saved && !this.spiked
      },

      saveButtonText() {
        if(this.saving) {
          return 'Saving'
        } else if(this.recentlySaved) {
          return 'Done';
        } else {
          return 'Save';
        }
      },

      saveButtonClass() {
        return {
          "fa-spinner": this.saving,
          "fa-spin": this.saving,
          "fa-check": this.recentlySaved,
          "fa-save": (!this.recentlySaved)
        };
      },

      primaryContentPath() {
        // NOTE: this should match HasFolder::BASE_CONTENT_PATH in Ruby
        const baseContentPath = 'content';
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // in JS 0 == Jan
        let day = date.getDate();

        return `${baseContentPath}/${year}/${month}/${day}`;
      },

      singleFile() {
        return this.$store.getters['storyMedia/byContentFor']('multiple_fields');
      },

      singleFilePresent() {
        return !!(this.singleFile && (this.singleFile.id || this.singleFile.medium.url));
      },

      currentUserIsLocker() {
        if(!this.currentUser?.id) return false

        return this.currentUser.id === this.locker?.id
      },

      lastUpdated() {
        return formatDateTime(this.updatedAt);
      },

      // Vuex helpers //
      // TODO: DRY these up
      id: {
        get() { return this.$store.getters['story/id'] },
        set(val) { this.$store.commit('story/SET_ID', val) }
      },

      slug: sync('story/slug'),
      headline: sync('story/headline'),
      userId: sync('story/user_id'),
      squeueId: sync('story/squeue_id'),

      pubDate: {
        get() { return this.$store.getters['story/pubDate'] },
        // Convert null to "" because nulls will be ignored and we won't
        // be able to ever clear out the pubDate.
        set(val) { this.$store.commit('story/UPDATE_PUB_DATE', val || '') }
      },

      dropHead: sync('story/drop_head'),
      webHead: sync('story/web_head'),
      overline: sync('story/overline'),
      byline: sync('story/byline'),
      credits: sync('story/credits'),
      endnote: sync('story/endnote'),
      keywords: sync('story/keywords'),
      sectionIds: sync('story/section_ids'),
      notes: sync('story/notes'),
      webCategoryIds: sync('story/web_category_ids'),
      notes: sync('story/notes'),
      featured: sync('story/featured'),
      ispublic: sync('story/ispublic'),
      commentsDisabled: sync('story/comments_disabled'),
      freelance: sync('story/freelance'),
      content: sync('story/content'),
      webSummary: sync('story/web_summary'),
      webSlug: sync('story/web_slug'),
      webBody: sync('story/web_body'),
      tag: sync('story/tag'),
      infoboxHeadline: sync('story/infobox_headline'),
      infoboxText: sync('story/infobox_text'),
      editingAttachments: sync('story/editingAttachments'),
      saved: sync('story/saved'),

      fileMode: {
        get() { return this.$store.getters['story/fileMode'] },
        set(val) {
          this.$store.commit('story/UPDATE_FILE_MODE', val);

          // Also delete or undelete the single file that is powering the story
          if((val == 'single' || val == 'per_field') && this.singleFilePresent) {
            this.$store.commit(
              'storyMedia/updateDestroy',
              {
                key: this.singleFile.use_as_content_for,
                value: (val === 'per_field')
              }
            );
          }
        }
      },

      twitterFeed: sync('story/twitter_feed'),
      tweet: sync('story/tweet'),
      googleSearchAd: sync('story/google_search_ad'),
      outbrainAd: sync('story/outbrain_ad')
    },

    watch: {
      // Automatically show the infobox section if it has any content
      infoboxHeadline(val) { if(val) { this.showInfobox = true } },
      infoboxText(val) { if(val) { this.showInfobox = true } },

      // Automatically show the CommunityQ section if it has any content
      webHead(val) { if(val) { this.showCommunityQ = true } },
      webSummary(val) { if(val) { this.showCommunityQ = true } },
      webBody(val) { if(val) { this.showCommunityQ = true } },
      webCategoryIds(val) { if(!isEmpty(val)) { this.showCommunityQ = true } },
      webSlug(val) { if(!isEmpty(val)) { this.showCommunityQ = true } },
      keywords(val) { if(val) { this.showCommunityQ = true } },

      // TODO: DRY this up between here and the storyMedium watcher in FileContentLinkedInput
      singleFile(storyMedium) {
        // TODO: this should probably centralized somewhere, maybe in Vuex
        if(!storyMedium) {
          this.fileContent = '';
        } else {
          const medium = storyMedium.medium;

          let postParams = new FormData();
          postParams.append('filename', medium.filename);

          if(BASE64_URL_MATCHER.test(medium.url)) {
            postParams.append('base64_url', medium.url);
          } else if(medium.url) {
            postParams.append('path', medium.url);
          } else {
            postParams.append('file', medium.file);
          }

          // TODO: generate this URL from a rails helper, if possible
          axios.post('/api/internal/content_file', postParams).then(({ data }) => {
            this.singleContentFileSuccessHandler(data);
          }).catch(err => {
            this.$store.dispatch('messages/addError', error);
          })
        }
      }
    },

    // This function is a Vue Router hook
    beforeRouteLeave(_to, _from, next) {
      const leave = (!this.askBeforeLeave() || confirm(UNLOAD_MSG))

      if(leave) {
        if(this.currentUserIsLocker) {
          this.$store.dispatch('story/unlock', { id: this.storyId })
        }

        next()
      } else {
        next(false)
      }
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.beforeUnloadHandler)
      window.addEventListener('beforeunload', this.unloadHandler)
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.unloadHandler)
      window.removeEventListener('beforeunload', this.beforeUnloadHandler)
    },

    async mounted() {
      this.$store.commit('story/RESET');

      if(this.storyId) {
        this.id = Number(this.storyId);
        await this.$store.dispatch('story/fetch', { id: this.storyId });
      } else {
        // If it's a new story, default the pub date to today
        this.pubDate = new Date();

        const { params: { issueId, pageId } } = this.$router.currentRoute
        if(pageId) {
          this.addPage({ issueId, pageId })
        } else if(issueId) {
          this.addIssue({ issueId })
        }
      }

      await this.currentUserStore.fetchOnce()

      jQuery([this.$refs.fileModePerField, this.$refs.fileModeSingle]).tooltip();

      this.loading = false
    },

    methods: {
      ...Vuex.mapActions('storyPlacements', ['addIssue', 'addPage']),

      singleFileChosen(event) {
        const fileInput = event.target
        const file = fileInput.files[0]

        this.$store.dispatch(
          'storyMedia/create',
          { file: file, useAsContentFor: 'multiple_fields' }
        )
      },

      singleContentFileSuccessHandler(data) {
        this.byline = data['byline'] || '';
        // TODO: fix content sometimes being HTML and sometimes benig plain
        this.content = data['html_content'] || data['content'] || '';
        this.credits = data['credits'] || '';
        this.headline = data['headline'] || '';
        this.overline = data['overline'] || '';
        this.dropHead = data['drop_head'] || '';
        this.infoboxText = data['infobox_text'] || '';
      },

      saveButtonAction(e) {
        if(this.saving) {
          // do nothing
        } else if (this.recentlySaved) {
          this.hasError = {};
          this.goBack();
        } else {
          this.save(e);
        }
      },

      save(e) {
        this.markAsSaving()

        return Promise.all([
          this.$store.dispatch('story/save'),
          this.$store.dispatch('storyPublications/updateAll'),
          (this.editingAttachments ? this.$store.dispatch('storyMedia/save'): null)
        ]).then(() => {
          this.markAsSaved()

          this.$store.dispatch('messages/addNotice', 'Story saved')
        }).catch(err => {
          if(e) e.preventDefault()
          this.saving = false
          this.$store.dispatch('messages/smartAdd', err, {root: true})
        })
      },

      markAsSaving() {
        this.$store.dispatch('messages/addNotice', 'Saving...')
        this.saving = true
      },

      markAsSaved() {
        this.hasError = {}

        this.editingAttachments = false

        // Change the "Save" button to "Done"
        this.recentlySaved = true
        this.saving = false

        // Swap the "Done" button back to "Save" in a few seconds
        setTimeout(() => { this.recentlySaved = false }, 3000)
      },

      goBack() {
        this.$router.push(storyRoute({
          storyRoute: 'stories',
          issueRoute: 'issue',
          pageRoute: 'issuePage',
          router: this.$router
        }))
      },

      spikeStory() {
        if(confirm(`Sure you want to spike "${this.name}"?`)) {
          this.warnOnLeave = false;

          axios.delete(
            `/api/internal/stories/${this.id}`
          ).then(() => { this.goBack() });
        }
      },

      askBeforeLeave() {
        const currentPrint = stateFingerprint(this.$store.getters['story/exceptFingerprintOmitted'])
        const dirty = (currentPrint !== this.cleanFingerprint)

        return (dirty && this.warnOnLeave)
      },

      beforeUnloadHandler(e) {
        if(this.askBeforeLeave()) {
          e.returnValue = UNLOAD_MSG;
          return UNLOAD_MSG;
        }

        return null;
      },

      unloadHandler() {
        if(!this.currentUserIsLocker) return

        this.$store.dispatch('story/unlockOnLeave')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn-group.new-single-file {
    display: flex;

    .btn {
      flex: 1;
    }
  }

  .sticky-top {
    position: sticky;
    top: 0px;
    z-index: 3;
    background-color: white;
    padding-top: 1em;
  }
</style>
