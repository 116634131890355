<template lang="pug">
.form-group
  template(v-if="anyExportFormats")
    .flex-between
      Label(text="Export fields")
      HelpPopover(title="Export fields" :content="this.exportFieldsHelp" placement="left")
    DropdownCheckboxes.block(label="Export fields" v-model="exportFormatIds" :options="exportFormatsStore.fieldsOnlyAsObject")
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { mapStores } from 'pinia'
  import { useExportFormatsStore } from '../../../stores/export_formats'

  import DropdownCheckboxes from '../../../components/dropdown_checkboxes.vue'
  import HelpPopover from '../../shared/help_popover.vue'
  import Label from '../../../components/shared/label.vue'

  export default {
    setup() {
      const exportFieldsHelp = `You can choose which fields to export to publish this story in print. By default, the system downloads every field for which it has data. So if content is entered in the overline, headline and subhead fields, they will all be included in your download.

You might want to choose what to export and override the default, however. For example, you might skip the pull quote, photos or breakout box for a story if you know you don't have room for them in print. Those items will still go to the website. Or, if someone has edited the story for errors that you've already flowed on the page, you can choose to simply download the story body again to reflow it.

Once you choose your export fields, you go to export drop down between the BACK button and the TRASH button and select ZIP to download the content.

You can also export a single piece of the story by just selecting it in the EXPORT drop down.`

      return { exportFieldsHelp }
    },


    components: {
      DropdownCheckboxes,
      HelpPopover,
      Label
    },

    computed: {
      ...mapStores(useExportFormatsStore),

      exportFormatIds: sync('story/export_format_ids'),

      anyExportFormats() {
        return Object.keys(this.exportFormatsStore.fieldsOnlyAsObject).length > 0
      }
    },

    async mounted() {
      await this.exportFormatsStore.fetchOnce()
    }
  }
</script>

<style>
  .default-format {
    width: 50%;
    float: left;
    font-size: 1.2em;
    margin: 2px 0;
    opacity: 0.8;
  }
</style>
