<template lang="pug">
.story-media
  // Search dialog box
  MediumSelect

  FileDropOverlay(@drop="uploadFiles")
    LoaderOverlay(:loading="saving")
      .well
        .row
          .col-md-6
            h3
              | Attachments
              |
              HelpPopover(title="Attachments" :content="this.attachmentsHelp")
          .col-md-6.text-right
            .btn-group
              UploadButton(@change="uploadFiles")
              .btn-group
                button.btn.btn-info.dropdown-toggle(type="button" data-toggle="dropdown")
                  i.fa.fa-caret-down

                ul.dropdown-menu
                  li
                    a(@click="openMediumSelect()")
                      i.fa.fa-search
                      |
                      | Find Media
                  template(v-if="addableFiles.length > 0")
                    li.dropdown-header Add found file:

                    template(v-for="file in addableFiles")
                      li
                        a(@click.prevent="addUnrecognizedMedium(file)")
                          i.fa.fa-file
                          |
                          | {{ mediaFileName(file) }}

        hr

        .alert.alert-info.text-center(v-if="noAttachments")
          | No attachments yet

        draggable(v-model="storyMediaArray" handle=".handle")
          .story-medium-wrapper(v-for="storyMedium in storyMediaArray")
            Row(:id="storyMedium.id" v-if="!editing")
            FormRow(:id="storyMedium.id" v-if="editing")

        hr

        button.btn.btn-default.btn-block(v-if="!editing" @click="edit" :disabled="noAttachments" type="button")
          i.fa.fa-pencil
          |
          | Edit Attachments

        div(v-if="editing")
          div
            button.btn.btn-success.btn-block(@click="save" type="button")
              i.fa.fa-save
              |
              | Save Attachments
          .text-right
            button.btn.btn-link.text-muted(@click="cancel" type="button")
              i.fa.fa-undo
              |
              | Cancel
</template>

<script>
  import draggable from 'vuedraggable'
  import { mapGetters } from 'vuex'
  import { sync } from 'vuex-pathify'
  import { mapStores } from 'pinia'
  import pLimit from 'p-limit'
  import { mediaFileName } from '../../../../utils/file_helpers'
  import { useMediaSelectingStore } from '../../../../stores/media_selecting'
  import { useUnrecognizedFilesStore } from '../../../../stores/unrecognized_files'

  import HelpPopover from '../../../shared/help_popover.vue'
  import Row from './row.vue'
  import FileDropOverlay from '../../../shared/file_drop_overlay.vue'
  import FormRow from './form_row.vue'
  import LoaderOverlay from '../../../shared/loader_overlay.vue'
  import MediumSelect from '../../../medium_select.vue'
  import UploadButton from '../../../shared/upload_button.vue'

  export default {
    setup() {
      const attachmentsHelp = `Attachments are items that are linked with your story in print or online. Attachments can include photos, videos, audio files,  PDFs, and Word or Excel documents.

You can drag and drop multiple attachments at a time over this box to load them into the system. Or the upload button can be used to select attachments on your computer individually. The arrow next to the upload button opens the attachment library or media manager.

Any captions or credit lines in the image metadata will be brought into the system.

Click on “Edit Attachments” to add or edit captions, delete files or indicate whether a photo is for print or web or both.

To change the order of images, click on the image and drag it up or down in the list view.

The system will not make other files available for print but will send them to your website.`

      return { attachmentsHelp }
    },

    components: {
      draggable,
      HelpPopover,
      Row,
      FileDropOverlay,
      FormRow,
      LoaderOverlay,
      MediumSelect,
      UploadButton
    },

    props: {
      storyId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        saving: false
      }
    },

    computed: {
      ...mapGetters('storyMedia', ['noAttachments']),
      editing: sync('story/editingAttachments'),
      ...mapStores(
        useMediaSelectingStore,
        useUnrecognizedFilesStore
      ),

      storyMediaArray: {
        get() {
          return this.$store.getters['storyMedia/attachments'];
        },
        set(storyMedia) {
          const newOrder = storyMedia.map(({ id }, i) => {
            return { id, list_order: (i + 1) }
          })

          this.$store.commit('storyMedia/UPDATE_LIST_ORDER', newOrder);
        }
      },

      addableFiles() {
        return this.unrecognizedFilesStore.unrecognizedFiles.filter(file => {
          return !this.$store.getters['storyMedia/paths'].includes(file.path);
        })
      }
    },

    watch: {
      storyMediaArray(val) {
        // If there are no attachments, then there is nothing to edit
        if(val?.length === 0) this.editing = false
      }
    },

    async mounted() {
      await this.$store.dispatch('storyMedia/fetchForStory', this.storyId)
      await this.unrecognizedFilesStore.fetchForStory(this.storyId)
    },

    methods: {
      mediaFileName(file) {
        return mediaFileName(file.url)
      },

      uploadFiles(files) {
        // Only allow one file upload at a time to avoid deadlocks in the database
        const limit = pLimit(1)

        this.uploading = true

        return Promise.all(
          files.map(file =>
            limit(() => this.$store.dispatch('storyMedia/create', { file }))
          )
        ).then(() => {
          this.saving = false
        }).catch(() => {
          this.saving = false
        })
      },

      edit() {
        this.editing = true
      },

      save() {
        this.$store.dispatch('storyMedia/save').then(() => {
          this.editing = false
        })
      },

      cancel() {
        this.editing = false
      },

      openMediumSelect() {
        this.mediaSelectingStore.show = true
      },

      addUnrecognizedMedium({ path }) {
        this.$store.dispatch('storyMedia/createFromUnrecognized', { path })
      }
    }
  }
</script>

<style lang="scss" scoped>
  a {
    cursor: pointer;
  }
</style>
