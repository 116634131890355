import { render, staticRenderFns } from "./story_form.vue?vue&type=template&id=740c4aa6&scoped=true&lang=pug"
import script from "./story_form.vue?vue&type=script&lang=js"
export * from "./story_form.vue?vue&type=script&lang=js"
import style0 from "./story_form.vue?vue&type=style&index=0&id=740c4aa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740c4aa6",
  null
  
)

export default component.exports