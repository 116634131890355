<template lang="pug">
.form-group(v-if="currentUserStore.currentUser.can_change_ownership")
  .flex-between
    label.control-label Owner
    HelpPopover(title="Owner" :content="this.ownerHelp" placement="left")
  select.form-control(v-model="userId")
    option(v-for="user in $store.getters['users/all']" :value="user.id") {{ user.name }}
</template>

<script>
  import HelpPopover from '../../shared/help_popover.vue';
  import { mapStores } from 'pinia'
  import { useCurrentUserStore } from '../../../stores/current_user'

  export default {
    setup() {
      const ownerHelp = `The system assigns the person who entered the story as its owner or originator. If you are loading stories for other staffers, it might be useful the change the owner to whomever wrote it.

Users can be added or edited by your system administrator.`

      return { ownerHelp }
    },

    components: {
      HelpPopover
    },

    props: {
      // NOTE: be careful that the value matches the option value. Number strings are a common issue.
      value: {
        type: Number,
        default() { return null }
      },
      default: {
        type: Number,
        default() { return null }
      }
    },

    data() {
      return {
        userId: null
      }
    },

    computed: {
      ...mapStores(useCurrentUserStore),
    },

    watch: {
      value(val) {
        this.userId = val
      },

      default(val) {
        if(this.userId === null) this.userId = val
      },

      userId(val) {
        // For component v-model support
        this.$emit('input', val)
      }
    },

    async mounted() {
      await this.$store.dispatch('users/fetchOnce')
    }
  }
</script>
