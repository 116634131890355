<template lang="pug">
.richtext-editor
  .flex-between(v-if="helpPresent")
    label.control-label {{ label }}
    .counts.text-right(v-if="editor && showCounts")
      span.label-group
        span.label.label-default Words
        span.label.label-info {{ wordCount }}
      | &nbsp; &nbsp;
      span.label-group
        span.label.label-default Inches
        span.label.label-info {{ inchCount }}&quot;
    HelpPopover(:title="label" :content="help")
  .row(v-else)
    .col-sm-6
      label.control-label(v-if="label")
        h4 {{ label }}
    .col-sm-6.counts.text-right(v-if="editor && showCounts")
      span.label-group
        span.label.label-default Words
        span.label.label-info {{ wordCount }}
      | &nbsp; &nbsp;
      span.label-group
        span.label.label-default Inches
        span.label.label-info {{ inchCount }}&quot;
  .menus(v-if="editor")
    Toolbar(:editor="editor")

    TableMenu(:editor="editor" :changeBus="changeBus")

  .content-wrapper(ref="contentWrapper")
    editor-content(:editor="editor")
</template>

<script>
  import Vue from 'vue'
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import CharacterCount from '@tiptap/extension-character-count'
  import HelpPopover from '../help_popover.vue'
  import Link from '@tiptap/extension-link'
  import ListKeymap from '@tiptap/extension-list-keymap'
  import StarterKit from '@tiptap/starter-kit'
  import Table from '@tiptap/extension-table'
  import TableCell from '@tiptap/extension-table-cell'
  import TableHeader from '@tiptap/extension-table-header'
  import TableRow from '@tiptap/extension-table-row'
  import TextAlign from '@tiptap/extension-text-align'
  import Underline from '@tiptap/extension-underline'
  import Toolbar from './toolbar.vue'
  import ToolbarButton from './toolbar_button.vue'
  import TableMenu from './table_menu.vue'

  export default {
    components: {
      EditorContent,
      HelpPopover,
      Toolbar,
      ToolbarButton,
      TableMenu
    },

    props: {
      classes: {
        type: String
      },

      help: String,

      editorId: {
        type: String
      },

      label: {
        type: String
      },

      showCounts: {
        default: false
      },

      type: {
        default: 'default'
      },

      value: {
        type: String,
        default: null,
      },

      loading: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        editor: null,
        inchCount: 0,
        changeBus: new Vue()
      }
    },

    computed: {
      wordCount() {
        return this.editor.storage.characterCount.words()
      },
      wordsPerInch() {
        return this.$store.getters['settings/wordsPerInch']
      },
      helpPresent() {
        return !!this.help
      }
    },

    watch: {
      loading(newValue, oldValue) {
        if(oldValue === true && newValue === false) {
          this.initializeEditor()
        }
      },

      value(value) {
        if(!this.editor) return

        const isSame = this.editor.getHTML() === value

        if (isSame) return

        this.editor.commands.setContent(value, false)
      },
    },

    mounted() {
      // If it's already loaded, just initialize it now, rather than waiting for the loading value to change.
      // Which shouldn't happen.
      if(!this.loading) {
        this.initializeEditor()
      }
      this.$refs.contentWrapper.addEventListener('mousedown', () => { this.emitChange('click') })
      this.$refs.contentWrapper.addEventListener('keydown', () => { this.emitChange('keydown') })
    },

    beforeDestroy() {
      this.editor.destroy()
    },

    methods: {
      initializeEditor() {
        this.editor = new Editor({
          content: this.value,
          editorProps: {
            attributes: {
              class: `${this.classes} form-control`,
              ...(this.editorId) ? { id: this.editorId } : {}
            }
          },
          extensions: [
            CharacterCount.configure({
              mode: 'nodeSize'
            }),
            Link.configure({
              openOnClick: false,
              defaultProtocol: 'https',
            }),
            ListKeymap,
            StarterKit.configure({
              history: {
                newGroupDelay: 1000,
              }
            }),
            Table.configure({
              resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
            TextAlign.configure({
              types: ['heading', 'paragraph'],
              }),
            Underline,
          ],
          onUpdate: ({editor}) => {
            this.$emit('input', editor.getHTML())
          }
        })
      },

      updateCounts() {
        this.wordCount = this.editor.storage.characterCount.words()
        this.inchCount = Math.round(this.wordCount / this.wordsPerInch)
      },

      emitChange(type) {
        this.changeBus.$emit('change', { type })
      }
    }
  }
</script>

<style lang="scss">
  .richtext-editor {
    .btn-toolbar {
      margin-bottom: 0.5em;
    }

    .tiptap {
      &.form-control {
        resize: vertical;
        overflow: auto;

        &.form-control-lg {
          height: 400px;
        }

        &.form-control-md {
          height: 200px;
        }

        &.form-control-sm {
          height: 100px;
        }
      }

      table {
        --selected-cell-color: #E8E8E8;
        border-collapse: collapse;
        margin: 0;
        overflow: hidden;
        table-layout: fixed;
        width: 100%;

        td,
        th {
          border: 1px solid gainsboro;
          box-sizing: border-box;
          min-width: 1em;
          padding: 6px 8px;
          position: relative;
          vertical-align: top;

          >* {
            margin-bottom: 0;
          }
        }

        th {
          background-color: lightgray;
          font-weight: bold;
          text-align: left;
        }

        .selectedCell {
          background: var(--selected-cell-color);
        }

        .column-resize-handle {
          background-color: black;
          bottom: -2px;
          pointer-events: none;
          position: absolute;
          right: -2px;
          top: 0;
          width: 4px;
        }
      }

      .tableWrapper {
        margin: 1.5rem 0;
        overflow-x: auto;
      }

      &.resize-cursor {
        cursor: ew-resize;
        cursor: col-resize;
      }
    }
  }
</style>
